






















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import './scss/FilePreview.scss';

@Component({
    name: 'FilePreview',
})

export default class FilePreview extends Vue {
    @Prop({}) urlFileData!: string;
    @Prop({}) urlViewFile!: string;
    @Prop({}) urlDownloadFile!: string;
    @Prop({}) urlDownloadSingedFile!: string;
}
